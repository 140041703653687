<div #chatBoxRef>

  <!-- <div *ngIf="isChatsLoading" class="popup-spinner-overlay" [style.height.px]="chatBoxRef.offsetHeight"
    [style.width.px]="chatBoxRef.offsetWidth" [style.top]="calc(chatBoxRef.offsetHeight)"
    [style.left]="calc(chatBoxRef.offsetWidth)">
  </div> -->

  <section [ngClass]="isDarkMode ? 'banner' : ''">
    <div class="audit-header">
      <div class="heading">
        <h2> Comment </h2>
        <h4 *ngIf="!dialogRef">Started by editing in the information below to update the task. </h4>
      </div>
      <i class="ph ph-x" (click)=onClose() style="cursor: pointer;"></i>
    </div>
    <div class="audit-body"  #chatContainer [scrollTop]="scrollTop">
      <div style="text-align: center;width: 100%;" *ngIf="comments.length < totalComments">
        <button mat-button 
              (click)="!isGettingMoreComments?loadMoreComments():''">
              <mat-icon [class.rotate-icon]="isGettingMoreComments">refresh</mat-icon>
              {{isGettingMoreComments?'Loading...':'Load More'}}
            </button>
      </div>
      <div *ngFor="let comment of comments; last as isLast" class="chat-container">
        <div class="comment-wrapper">
          <div class="d-flex comment-section">
            <div class="avatar">
              <img src="../../../assets/images/header/comment.png" alt="">
            </div>
            <div class="content">
              <div class="comment-content row">
                <p class="comment-name">{{comment.createdBy==loggedInUser?'You':comment.userFullName}}</p>
                <p class="comment-date">{{isSendingComment && isLast? 'Sending...':
                  comment.addedFromUI?formatTime(comment.createTimeStamp):(comment.createTimeStamp )
                  }}</p>
              </div>
              <div class="comments-txt">
                <p [innerHTML]="comment?.comment"></p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="audit-footer-btn">
      <div class="seconize-input-filed col-9 comment-filed align-self-strech">
        <mat-form-field class="example-full-width" appearance="outline" style="width: 100%; margin-top: 4px;">
          <textarea matInput matInput [(ngModel)]="newComment" rows="1"
          placeholder="Type your comment here"></textarea>
        </mat-form-field>
      </div>
      <div class="seconize-btn align-self-strech row">
        <button mat-fab extended class="primary-btn btn-icon-txt" (click)="sendComment()">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </div>
  </section>
</div>