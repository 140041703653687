import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentDemoComponent } from './components/component-demo/component-demo.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { AuthGuard } from './authentication/auth/auth.guard';
import { KanbanViewComponent } from './components/kanban-view/kanban-view.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../app/modules/home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('../app/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'controls',
    component: ComponentDemoComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'kanban-view-demo',
    component: KanbanViewComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
