<div class="d-flex align-items-center justify-content-center logo-container">
  <div class="seconize-logo">
    <img
      style="width: 200px"
      src="../../../assets/images/login/seconize-logo.png"
      alt=""
    />
  </div>
  <div class="derisk-logo">
    <img
      style="width: 200px"
      src="../../../assets/images/login/logo-new.png"
      alt=""
    />
  </div>
</div>

<div class="container">
  <svg
    width="254"
    height="212"
    viewBox="0 0 254 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7337_2)">
      <path
        d="M159.663 65.6294C154.185 31.5931 141.263 7.69531 126.226 7.69531C111.188 7.69531 98.2664 31.5931 92.7879 65.6294H159.663ZM89.9542 102.219C89.9542 110.681 90.4076 118.799 91.201 126.613H161.212C162.006 118.799 162.459 110.681 162.459 102.219C162.459 93.7579 162.006 85.6395 161.212 77.826H91.201C90.4076 85.6395 89.9542 93.7579 89.9542 102.219ZM212.635 65.6294C201.829 39.7496 179.953 19.7395 152.938 11.6592C162.157 24.5419 168.505 43.9422 171.829 65.6294H212.635ZM99.4754 11.6592C72.4986 19.7395 50.5846 39.7496 39.8165 65.6294H80.6219C83.909 43.9422 90.2565 24.5419 99.4754 11.6592ZM216.678 77.826H173.341C174.134 85.8301 174.588 94.0247 174.588 102.219C174.588 110.414 174.134 118.609 173.341 126.613H216.64C218.718 118.799 219.889 110.681 219.889 102.219C219.889 93.7579 218.718 85.6395 216.678 77.826ZM77.8637 102.219C77.8637 94.0247 78.3171 85.8301 79.1105 77.826H35.7737C33.7335 85.6395 32.5244 93.7579 32.5244 102.219C32.5244 110.681 33.7335 118.799 35.7737 126.613H79.0728C78.3171 118.609 77.8637 110.414 77.8637 102.219ZM92.7879 138.809C98.2664 172.846 111.188 196.743 126.226 196.743C141.263 196.743 154.185 172.846 159.663 138.809H92.7879ZM152.976 192.779C179.953 184.699 201.867 164.689 212.673 138.809H171.867C168.542 160.496 162.195 179.897 152.976 192.779ZM39.8165 138.809C50.6224 164.689 72.4986 184.699 99.5132 192.779C90.2942 179.897 83.9467 160.496 80.6219 138.809H39.8165Z"
        fill="#EEF5FC"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M198.27 152.323C182.096 152.323 153.252 152.323 137.078 152.323C134.46 152.323 131.949 153.364 130.098 155.213C128.248 157.065 127.208 159.576 127.208 162.193C127.208 165.328 127.208 168.928 127.208 172.063C127.208 174.68 128.248 177.191 130.098 179.043C131.949 180.892 134.46 181.933 137.078 181.933C147.177 181.933 166 181.933 182.12 181.933C183.209 181.933 184.093 181.048 184.093 179.959C184.093 178.869 183.209 177.985 182.12 177.985C166 177.985 147.177 177.985 137.078 177.985C135.507 177.985 134 177.361 132.891 176.25C131.78 175.14 131.156 173.634 131.156 172.063C131.156 168.928 131.156 165.328 131.156 162.193C131.156 160.622 131.78 159.116 132.891 158.006C134 156.895 135.507 156.271 137.078 156.271H198.27C199.36 156.271 200.244 155.387 200.244 154.297C200.244 153.208 199.36 152.323 198.27 152.323Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M216.036 136.531C216.036 133.913 214.996 131.403 213.146 129.551C211.295 127.701 208.784 126.661 206.166 126.661C189.992 126.661 153.252 126.661 137.078 126.661C134.46 126.661 131.949 127.701 130.098 129.551C128.248 131.403 127.208 133.913 127.208 136.531C127.208 139.666 127.208 143.266 127.208 146.401C127.208 149.018 128.248 151.529 130.098 153.381C131.949 155.23 134.46 156.27 137.078 156.27C153.252 156.27 189.992 156.27 206.166 156.27C208.784 156.27 211.295 155.23 213.146 153.381C214.996 151.529 216.036 149.018 216.036 146.401C216.036 143.266 216.036 139.666 216.036 136.531ZM212.088 136.531C212.088 139.666 212.088 143.266 212.088 146.401C212.088 147.972 211.464 149.478 210.353 150.587C209.244 151.699 207.738 152.323 206.166 152.323H137.078C135.507 152.323 134 151.699 132.891 150.587C131.78 149.478 131.156 147.972 131.156 146.401C131.156 143.266 131.156 139.666 131.156 136.531C131.156 134.96 131.78 133.454 132.891 132.344C134 131.233 135.507 130.609 137.078 130.609H206.166C207.738 130.609 209.244 131.233 210.353 132.344C211.464 133.454 212.088 134.96 212.088 136.531Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M214.216 177.773C215.393 176.115 216.036 174.122 216.036 172.063C216.036 168.928 216.036 165.328 216.036 162.193C216.036 159.576 214.996 157.065 213.147 155.213C211.295 153.364 208.784 152.323 206.167 152.323C203.901 152.323 201.232 152.323 198.271 152.323C197.181 152.323 196.297 153.208 196.297 154.297C196.297 155.387 197.181 156.271 198.271 156.271H206.167C207.738 156.271 209.244 156.895 210.353 158.006C211.465 159.116 212.089 160.622 212.089 162.193C212.089 165.328 212.089 168.928 212.089 172.063C212.089 173.298 211.702 174.495 210.997 175.49C210.365 176.378 210.576 177.612 211.465 178.241C212.353 178.871 213.587 178.662 214.216 177.773Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M216.036 110.87C216.036 108.252 214.996 105.741 213.146 103.89C211.295 102.04 208.784 101 206.166 101C189.992 101 153.252 101 137.078 101C134.46 101 131.949 102.04 130.098 103.89C128.248 105.741 127.208 108.252 127.208 110.87C127.208 114.004 127.208 117.605 127.208 120.74C127.208 123.357 128.248 125.868 130.098 127.719C131.949 129.569 134.46 130.609 137.078 130.609C153.252 130.609 189.992 130.609 206.166 130.609C208.784 130.609 211.295 129.569 213.146 127.719C214.996 125.868 216.036 123.357 216.036 120.74C216.036 117.605 216.036 114.004 216.036 110.87ZM212.088 110.87C212.088 114.004 212.088 117.605 212.088 120.74C212.088 122.311 211.464 123.817 210.353 124.926C209.244 126.038 207.738 126.661 206.166 126.661H137.078C135.507 126.661 134 126.038 132.891 124.926C131.78 123.817 131.156 122.311 131.156 120.74C131.156 117.605 131.156 114.004 131.156 110.87C131.156 109.299 131.78 107.792 132.891 106.683C134 105.572 135.507 104.948 137.078 104.948H206.166C207.738 104.948 209.244 105.572 210.353 106.683C211.464 107.792 212.088 109.299 212.088 110.87Z"
      fill="#2E7DC2"
    />
    <path
      d="M140.039 170.089C141.674 170.089 143 168.763 143 167.128C143 165.493 141.674 164.167 140.039 164.167C138.404 164.167 137.078 165.493 137.078 167.128C137.078 168.763 138.404 170.089 140.039 170.089Z"
      fill="#2E7DC2"
    />
    <path
      d="M140.039 144.427C141.674 144.427 143 143.101 143 141.466C143 139.831 141.674 138.505 140.039 138.505C138.404 138.505 137.078 139.831 137.078 141.466C137.078 143.101 138.404 144.427 140.039 144.427Z"
      fill="#2E7DC2"
    />
    <path
      d="M140.039 118.766C141.674 118.766 143 117.44 143 115.805C143 114.169 141.674 112.844 140.039 112.844C138.404 112.844 137.078 114.169 137.078 115.805C137.078 117.44 138.404 118.766 140.039 118.766Z"
      fill="#2E7DC2"
    />
    <path
      d="M149.908 170.089C151.543 170.089 152.869 168.763 152.869 167.128C152.869 165.493 151.543 164.167 149.908 164.167C148.273 164.167 146.947 165.493 146.947 167.128C146.947 168.763 148.273 170.089 149.908 170.089Z"
      fill="#2E7DC2"
    />
    <path
      d="M149.908 144.427C151.543 144.427 152.869 143.101 152.869 141.466C152.869 139.831 151.543 138.505 149.908 138.505C148.273 138.505 146.947 139.831 146.947 141.466C146.947 143.101 148.273 144.427 149.908 144.427Z"
      fill="#2E7DC2"
    />
    <path
      d="M149.908 118.766C151.543 118.766 152.869 117.44 152.869 115.805C152.869 114.169 151.543 112.844 149.908 112.844C148.273 112.844 146.947 114.169 146.947 115.805C146.947 117.44 148.273 118.766 149.908 118.766Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M178.531 167.128C178.531 165.493 177.204 164.167 175.57 164.167H159.778C158.144 164.167 156.817 165.493 156.817 167.128C156.817 168.762 158.144 170.089 159.778 170.089H175.57C177.204 170.089 178.531 168.762 178.531 167.128Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M178.531 141.466C178.531 139.831 177.204 138.505 175.57 138.505H159.778C158.144 138.505 156.817 139.831 156.817 141.466C156.817 143.1 158.144 144.427 159.778 144.427H175.57C177.204 144.427 178.531 143.1 178.531 141.466Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M178.531 115.805C178.531 114.17 177.204 112.844 175.57 112.844H159.778C158.144 112.844 156.817 114.17 156.817 115.805C156.817 117.439 158.144 118.766 159.778 118.766H175.57C177.204 118.766 178.531 117.439 178.531 115.805Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M181.234 195.75H215.308C217.398 195.75 219.333 194.648 220.401 192.85C221.467 191.053 221.508 188.827 220.507 186.991C216.068 178.854 208.054 164.16 203.47 155.757C202.432 153.854 200.438 152.672 198.271 152.672C196.103 152.672 194.11 153.854 193.071 155.757C188.488 164.16 180.474 178.854 176.034 186.991C175.033 188.827 175.075 191.053 176.141 192.85C177.209 194.648 179.143 195.75 181.234 195.75ZM181.234 191.802C180.537 191.802 179.891 191.434 179.536 190.834C179.181 190.236 179.167 189.494 179.501 188.882C183.94 180.745 191.954 166.051 196.538 157.648C196.883 157.015 197.548 156.62 198.271 156.62C198.993 156.62 199.659 157.015 200.004 157.648L217.041 188.882C217.375 189.494 217.361 190.236 217.006 190.834C216.65 191.434 216.005 191.802 215.308 191.802H181.234Z"
      fill="#2E7DC2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M200.245 179.958V168.115C200.245 167.025 199.36 166.141 198.271 166.141C197.181 166.141 196.297 167.025 196.297 168.115V179.958C196.297 181.048 197.181 181.932 198.271 181.932C199.36 181.932 200.245 181.048 200.245 179.958Z"
      fill="#2E7DC2"
    />
    <path
      d="M198.271 187.854C199.361 187.854 200.245 186.97 200.245 185.88C200.245 184.79 199.361 183.906 198.271 183.906C197.181 183.906 196.297 184.79 196.297 185.88C196.297 186.97 197.181 187.854 198.271 187.854Z"
      fill="#2E7DC2"
    />
    <defs>
      <clipPath id="clip0_7337_2">
        <rect
          width="187.402"
          height="195.146"
          fill="white"
          transform="translate(32.5244 4.64648)"
        />
      </clipPath>
    </defs>
  </svg>

  @if(source != 'no-internet') {
  <div class="reach-out">
    You can reach us at <span>Support&#64;seconize.co</span>
  </div>

  <div class="right-back">We'll be right back!</div>
  }@else {
  <div class="right-back light">
    Network error. Please verify your internet connection and retry.
  </div>
  }
</div>
