<div class="kanban-container row">
  <div
    class="section col-3 drag"
    *ngFor="let section of sections"
    pDroppable
    (onDrop)="drop(section, $event)"
  >
    <p class="text-center surface-border border-bottom-1">
      {{ section.name }}
    </p>
    <div class="list-none flex flex-column drop-column gap-2 p-0 m-0">
      <p-card
        *ngFor="let product of section.products; let productIndex = index"
        class="p-2 border-round shadow-1 mb-2 product-item"
        dragEffect="move"
        pDraggable
        (onDragStart)="dragStart(product, productIndex)"
        (onDragEnd)="dragEnd()"
      >
        {{ product.name }}
      </p-card>
    </div>
  </div>
</div>
