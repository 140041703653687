import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit, OnChanges {
  source: any;
  timer: any;
  pageRoutesForJob = ['administration/jobs', 'administration/reports'];
  constructor(
    private aRoute: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {
    this.aRoute.queryParams.subscribe((res: any) => {
      console.log('REs', res);
      this.source = decodeURIComponent(res?.source || '');
      if (this.source != 'no-internet') {
        this.startTimer();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }
  ngOnInit() {}

  startTimer() {
    this.timer = setInterval(() => {
      if (this.pageRoutesForJob.includes(this.source)) {
        this.callJobManagerUtil();
      } else {
        this.callUtil();
      }
    }, 5000);
  }

  callJobManagerUtil() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http
      .get(environment.url + '/jobmanager/utils/me', { headers })
      .subscribe((res: any) => {
        if (res) {
          this.clearTimer();
          this.router.navigateByUrl(this.source);
        }
      });
  }

  callUtil() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http
      .get(environment.url + '/utils/me', { headers })
      .subscribe((res: any) => {
        if (res) {
          this.clearTimer();
          this.router.navigateByUrl(this.source);
        }
      });
  }

  clearTimer() {
    clearInterval(this.timer);
  }

  ngOnDestroy(): void {
    this.clearTimer();
  }
}
