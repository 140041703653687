import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { LoaderService } from './shared/genric-service/loader.service';
import { DrawerService } from './shared/services/drawer.service';
import { GenericService } from './shared/genric-service/generic.service';
import {
  OTHER,
  CONNECTORS,
  DB,
  END_POINT,
  K8S,
  MOBILE_APP,
  NETWORK,
  SERVER,
  WEBSITE,
  AUDITS,
  CLOUD_CONFIG,
  FAILED,
  // FILE_TYPES
} from './shared/icons';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PrimeNGConfig } from 'primeng/api';
import { Aura } from 'primeng/themes/aura';
import {
  ConnectionService,
  ConnectionServiceOptions,
  ConnectionState,
} from 'ng-connection-service';
import { tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatSidenav;
  title = 'drc-ui-v3';
  isOpened: boolean = false;
  showLoader: boolean = false;
  drawerType: string = '';
  isDarkMode: boolean = false;
  updateData?: any;
  selectedControlData?: any;
  drawerResponse!: { state: boolean; type: string; data?: any };
  loaderText: string = 'Fetching Data';
  status!: string;
  currentState!: ConnectionState;
  private wasOffline = false;
  currentUrl = '';
  previousUrl = '';
  constructor(
    private drawerService: DrawerService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private loaderService: LoaderService,
    genericService: GenericService,
    private config: PrimeNGConfig,
    private connectionService: ConnectionService,
    private router: Router
  ) {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });

    // Define custom theme
    // primary color #065FD4
    Aura.semantic.primary = {
      50: '#e3f0ff',
      100: '#c7e1ff',
      200: '#a0caff',
      300: '#74b0ff',
      400: '#4594ff',
      500: '#065FD4', // Primary color
      600: '#044ab0',
      700: '#03388c',
      800: '#022966',
      900: '#011b45',
      950: '#011030',
    };

    // Default theme configuration
    this.config.theme.set({
      preset: Aura,
      options: {
        prefix: 'p',
        darkModeSelector: 'light-mode',
        cssLayer: false,
      },
    });

    this.loaderService.status.subscribe(
      (val: { showLoader: boolean; text: string }) => {
        setTimeout(() => {
          this.showLoader = val.showLoader;
          this.loaderText = val.text;
        });
      }
    );
    this.drawerService.currentDrawerState.subscribe(
      (res: {
        state: boolean;
        type: string;
        data?: any;
        updateData?: any;
        controlData?: any;
      }) => {
        this.drawerResponse = res;
        this.isOpened = res.state;
        if (res.type && res.type == 'CLOSING') {
          this.drawerType = '';
        } else {
          this.drawerType = res.type;
        }
        if (res && res.updateData) {
          this.updateData = res.updateData;
        } else {
          this.updateData = undefined;
        }
        if (res && res.controlData) {
          this.selectedControlData = res.controlData;
        } else {
          this.selectedControlData = undefined;
        }
        if (this.drawer) {
          this.showLoader = false;
          this.drawer.toggle();
        } else {
          this.drawerType = '';
        }

        this.registerIcons();
      }
    );

    genericService.isDarkMode.subscribe((res: boolean) => {
      if (document.getElementsByTagName('body').length > 0) {
        if (res) {
          document.getElementsByTagName('html')[0].classList.add('dark');
          document.getElementsByTagName('body')[0].classList.add('dark');
          this.isDarkMode = res;
        } else {
          document.getElementsByTagName('body')[0].classList.remove('dark');
          document.getElementsByTagName('html')[0].classList.remove('dark');
          this.isDarkMode = res;
        }
      }
    });
  }
  ngOnInit() {
    const options: ConnectionServiceOptions = {
      enableHeartbeat: false,
      heartbeatUrl: 'https://localhost:4200',
      heartbeatInterval: 2000,
    };

    this.connectionService
      .monitor(options)
      .pipe(
        tap((newState: ConnectionState) => {
          this.currentState = newState;

          if (this.currentState.hasNetworkConnection) {
            this.status = 'ONLINE';
            if (this.wasOffline) {
              this.router.navigateByUrl(this.previousUrl);
              this.wasOffline = false;
            }
          } else {
            this.status = 'OFFLINE';
            if (!this.wasOffline) {
              this.router.navigate(['/error'], {
                queryParams: { source: 'no-internet' },
              });
              this.wasOffline = true;
            }
          }
        })
      )
      .subscribe();
  }

  private registerIcons() {
    // Registering custom icons for material
    this.iconRegistry.addSvgIconLiteral(
      'SERVER',
      this.sanitizer.bypassSecurityTrustHtml(SERVER)
    );

    this.iconRegistry.addSvgIconLiteral(
      'MOBILE_APP',
      this.sanitizer.bypassSecurityTrustHtml(MOBILE_APP)
    );
    this.iconRegistry.addSvgIconLiteral(
      'WEBSITE',
      this.sanitizer.bypassSecurityTrustHtml(WEBSITE)
    );
    this.iconRegistry.addSvgIconLiteral(
      'CLOUD_CONFIG',
      this.sanitizer.bypassSecurityTrustHtml(CLOUD_CONFIG)
    );
    this.iconRegistry.addSvgIconLiteral(
      'FAILED',
      this.sanitizer.bypassSecurityTrustHtml(FAILED)
    );
    this.iconRegistry.addSvgIconLiteral(
      'K8S',
      this.sanitizer.bypassSecurityTrustHtml(K8S)
    );
    this.iconRegistry.addSvgIconLiteral(
      'NETWORK',
      this.sanitizer.bypassSecurityTrustHtml(NETWORK)
    );
    this.iconRegistry.addSvgIconLiteral(
      'END_POINT',
      this.sanitizer.bypassSecurityTrustHtml(END_POINT)
    );
    this.iconRegistry.addSvgIconLiteral(
      'DB',
      this.sanitizer.bypassSecurityTrustHtml(DB)
    );
    this.iconRegistry.addSvgIconLiteral(
      'connectors',
      this.sanitizer.bypassSecurityTrustHtml(CONNECTORS)
    );
    this.iconRegistry.addSvgIconLiteral(
      'internal',
      this.sanitizer.bypassSecurityTrustHtml(AUDITS)
    );
    this.iconRegistry.addSvgIconLiteral(
      'OTHER',
      this.sanitizer.bypassSecurityTrustHtml(OTHER)
    );
  }
}
