
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { DragDropModule } from 'primeng/dragdrop';

@Component({
  selector: 'app-kanban-view',
  standalone: true,
  imports: [CommonModule, DragDropModule, CardModule],
  templateUrl: './kanban-view.component.html',
  styleUrls: ['./kanban-view.component.scss']
})
export class KanbanViewComponent {
  sections: any[] = [];
  draggedProduct: any = null;
  draggedProductIndex: number | null = null;

  ngOnInit() {
    this.sections = [
      { id: 1, name: 'Section 1', products: [{ id: '1', name: 'Black Watch' }, { id: '2', name: 'Bamboo Watch' },] },
      { id: 2, name: 'Section 2', products: [] },
      { id: 3, name: 'Section 3', products: [] },
      { id: 4, name: 'Section 4', products: [] },
    ];
  }

  dragStart(product: any, index: number) {
    this.draggedProduct = product;
    this.draggedProductIndex = index;
  }

  drop(targetSection: any, event: DragEvent) {
  if (this.draggedProduct) {
    const sourceSection = this.findSectionContainingProduct(this.draggedProduct);

    if (sourceSection) {
      // Remove product from source section
      sourceSection.products = sourceSection.products.filter((p: any) => p.id !== this.draggedProduct.id);

      const targetElement = (event.target as HTMLElement).closest('.product-item');
      const targetIndex = targetElement
        ? Array.from(targetElement.parentNode!.children).indexOf(targetElement)
        : targetSection.products.length;

      if (sourceSection.id === targetSection.id) {
        // Reordering within the same section
        targetSection.products.splice(targetIndex, 0, this.draggedProduct);
      } else {
        // Moving to a different section
        targetSection.products.push(this.draggedProduct);
      }
    }

    this.draggedProduct = null;
    this.draggedProductIndex = null;
  }
}


  dragEnd() {
    this.draggedProduct = null;
    this.draggedProductIndex = null;
  }

  findSectionContainingProduct(product: any) {
    return this.sections.find((section) =>
      section.products.some((p: any) => p.id === product.id)
    );
  }
}
